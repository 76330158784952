import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VisitorModel } from 'client/data/models/visitor';
import { Link } from 'site-modules/shared/components/link/link';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';

import './privacy-global-disclaimer.scss';

function PrivacyGlobalDisclaimerUI({ privacyAccepted, setModelValue }) {
  function acceptPrivacy() {
    setModelValue('privacyAccepted', VisitorModel, 'true');
  }

  if (isUndefined(privacyAccepted)) {
    return null;
  }

  return (
    <Experiment name="core-6592-privacy2" showDefault>
      <Recipe name="ctrl" isDefault />
      <Recipe name="chal">
        {!privacyAccepted && (
          <div
            className="privacy-global-disclaimer bottom-0 left-0 right-0 d-flex justify-content-center bg-white pb-1 pb-md-1_5"
            data-tracking-parent="privacy-disclaimer"
          >
            <div className="privacy-content pos-r w-100 pt-0_75 pt-md-1 ps-1_5 ps-md-0_75 pe-2_5 pe-md-1_25">
              <div className="privacy-text medium text-cool-gray-10 pt-1">
                By accessing this website, you acknowledge that Edmunds and its third party business partners may use
                cookies, pixels, and similar technologies to collect information about you and your interactions with
                the website as described in our{' '}
                <Link to="/about/privacy.html" className="text-blue-40 text-underline">
                  Privacy Statement
                </Link>
                , and you agree that your use of the website is subject to our{' '}
                <Link to="/about/visitor-agreement.html" className="text-blue-40 text-underline">
                  Visitor Agreement
                </Link>
                .
              </div>
              <button
                onClick={acceptPrivacy}
                className="close-btn text-cool-gray-10 size-20 bg-transparent border-0 rounded-8 pos-a px-0_25 py-0_25"
                data-tracking-id="close_privacy_disclaimer"
              >
                <span className="visually-hidden">Close disclaimer</span>
                <i className="icon-cross2" aria-hidden />
              </button>
            </div>
          </div>
        )}
      </Recipe>
    </Experiment>
  );
}

PrivacyGlobalDisclaimerUI.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  privacyAccepted: PropTypes.bool,
};

PrivacyGlobalDisclaimerUI.defaultProps = {
  privacyAccepted: undefined,
};

export const PrivacyGlobalDisclaimer = connectToModel(PrivacyGlobalDisclaimerUI, {
  privacyAccepted: bindToPath('privacyAccepted', VisitorModel),
});
